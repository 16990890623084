import { mapState } from 'vuex';
import {
  onlyNum,
  isMobile,
  isPhone,
  isTel,
  validateIdCardDate,
} from '../utils/validate';
// import html2canvas from 'html2canvas';
// import printJS from 'print-js';
// import { printBarCode } from '@/print/print.js';
// import FileSaver from 'file-saver';
// import XLSX from 'xlsx';

function getType(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1);
}

export const normal = {
  data() {
    return {
      loading: false,
      dataRule: {},
    };
  },
  methods: {
    $dialog(dialog, ...params) {
      this[`${dialog}Visible`] = true;
      this.$nextTick(() => {
        this.$refs[dialog].init(...params);
      });
    },
    _handle({ url, method, params, data, before, after, showLoading, error }) {
      if (showLoading) this.loading = true;
      before && before();
      this.$http({
        url: url,
        method,
        params: params,
        data: data,
      })
        .then(({ data }) => {
          if (data && data.code === 0) {
            after && after(data);
          } else {
            this.$error(data.msg);
            error && error(data);
          }
        })
        .finally(() => {
          if (showLoading) this.loading = false;
        });
    },
    $handle({
      tip,
      url,
      validate = false,
      method = 'post',
      params,
      data,
      before,
      after,
      showLoading = true,
      error,
    }) {
      if (validate) {
        this.$refs.dataForm.validate((valid) => {
          if (valid) {
            if (tip) {
              this.$warning(tip)
                .then(() => {
                  this._handle({
                    url,
                    method,
                    params,
                    data,
                    before,
                    after,
                    showLoading,
                    error,
                  });
                })
                .catch(() => {});
            } else {
              this._handle({
                url,
                method,
                params,
                data,
                before,
                after,
                showLoading,
                error,
              });
            }
          }
        });
      } else {
        if (tip) {
          this.$warning(tip)
            .then(() => {
              this._handle({ url, method, params, data, before, after, error });
            })
            .catch(() => {});
        } else {
          this._handle({ url, method, params, data, before, after, error });
        }
      }
    },
    $initRule(validateForm = 'dataForm', rule = 'dataRule') {
      setTimeout(() => {
        for (const child of this._collectItem(this.$refs[validateForm])) {
          const { propsData } = child.$options;
          if (propsData?.prop && Reflect.has(child.$attrs, 'require')) {
            const label = propsData.label?.replaceAll(':', '');
            if (this[rule][propsData.prop]) {
              this[rule][propsData.prop].unshift({
                required: true,
                message: `${label}不能为空`,
                trigger: 'blur',
              });
            } else {
              this.$set(this[rule], propsData.prop, [
                {
                  required: true,
                  message: `${label}不能为空`,
                  trigger: 'blur',
                },
              ]);
            }
          }
        }
      }, 0);
    },
    _collectItem(obj, result = []) {
      if (obj.$options._componentTag === 'el-form-item') {
        result.push(obj);
      } else if (obj.$children?.length) {
        for (const child of obj.$children) {
          this._collectItem(child, result);
        }
      }
      return result;
    },
    $api({ url, params, before, after }) {
      before && before();
      this.$http({
        url: url,
        method: 'get',
        params,
      }).then(({ data }) => {
        if (data?.code === 0) {
          after && after(data);
        } else {
          this.$error(data?.msg);
        }
      });
    },
  },
};

export const listMixin = {
  data() {
    return {
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataList: [],
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      loading: false,
      dataForm: {},
    };
  },
  methods: {
    $getDataList({ url, page, params, before, after }) {
      this.dataListLoading = true;
      this.pageIndex = page ?? this.pageIndex;
      before && before();
      this.$http({
        url: url,
        method: 'get',
        params: {
          limit: this.pageSize,
          page: this.pageIndex,
          ...this.dataForm,
          ...params,
        },
      }).then(({ data }) => {
        if (data?.code === 0) {
          const list =
            data.page?.list ??
            data.list ??
            data.page ??
            data.tBusinessCost ??
            [];
          if (list.length === 0 && this.pageIndex > 1) {
            this.pageIndex--;
            this.getDataList();
          } else {
            this.dataList = list;
            this.totalPage = data.page?.totalCount;
            after && after(data);
            this.dataListLoading = false;
          }
        }
      });
    },
    $reset({ resetObj = this.dataForm, after }) {
      Object.keys(resetObj).forEach((key) => {
        resetObj[key] = null;
      });
      after && after();
    },
    _handleDelete({ url, params, data, before, after }) {
      this.loading = true;
      before && before();
      this.$http({
        url: url,
        method: 'post',
        params: params,
        data: data,
      })
        .then(({ data }) => {
          if (data && data.code === 0) {
            this.getDataList();
            this.$success();
            after && after();
          } else {
            this.$error(data.msg);
          }
        })
        .finally(() => (this.loading = false));
    },
    $handleDelete({ tip, url, params = {}, data = {}, before, after }) {
      if (tip) {
        this.$warning(tip)
          .then(() => {
            this._handleDelete({ url, params, data, before, after });
          })
          .catch(() => {});
      } else {
        this._handleDelete({ url, params, data, before, after });
      }
    },
    select(selection, row) {
      this.$refs.dataList.clearSelection();
      if (selection.length === 0) return;
      this.$refs.dataList.toggleRowSelection(row, true);
    },
    handleSelectAll() {
      this.$refs.dataList.clearSelection();
    },
    handleRowClick(row, after = () => {}) {
      const selectData = this.dataListSelections;
      this.$refs.dataList.clearSelection();
      if (selectData.length === 1) {
        selectData.forEach((item) => {
          if (item === row) {
            this.$refs.dataList.toggleRowSelection(row, false);
          } else {
            this.$refs.dataList.toggleRowSelection(row, true);
          }
        });
      } else {
        this.$refs.dataList.toggleRowSelection(row, true);
      }
      after && after(1);
    },
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
  },
  computed: {
    ...mapState('common', ['currentRouteName']),
  },
  watch: {
    currentRouteName() {
      if (
        this.$store.state.common.mainTabsActiveName ===
          this.currentRouteName.name &&
        this.pageName === this.currentRouteName.name
      )
        this.getDataList();
    },
  },
};

export const threeListMixin = {
  data() {
    return {
      dataList: [],
      detailList: [],
      serialList: [],
      dataListIndex: 1,
      dataListSize: 20,
      dataListCount: 0,
      detailListIndex: 1,
      detailListSize: 10,
      detailListCount: 0,
      serialListIndex: 1,
      serialListSize: 10,
      serialListCount: 0,
      dataListLoading: false,
      detailListLoading: false,
      serialListLoading: false,
      dataListSelections: [],
      detailListSelections: [],
      serialListSelections: [],
      dataForm: {},
      addOrUpdateVisible: false,
      loading: false,
    };
  },
  methods: {
    $clickRow(row, list, after = () => {}, rowColor) {
      this.$refs[list].clearSelection();
      if (this[`${list}Selections`]?.length) {
        this.$refs[list].toggleRowSelection(row, false);
        if (rowColor) {
          return '';
        }
      } else {
        this.$refs[list].toggleRowSelection(row, true);
        if (rowColor) {
          return 'danger-row';
        }
      }
      after && after(1);
      rowColor && rowColor(1);
    },
    $select([selection, row], list) {
      this.$refs[list].clearSelection();
      if (!selection.length) return;
      this.$refs[list].toggleRowSelection(row, true);
    },
    $clearSelection(list) {
      this.$refs[list].clearSelection();
    },
    $sizeChange(val, list) {
      this[`${list}Size`] = val;
      this[`${list}Index`] = 1;
      this[`get${list.substring(0, 1).toUpperCase()}${list.substring(1)}`]();
    },
    $currentChange(val, list) {
      this[`${list}Index`] = val;
      this[`get${list.substring(0, 1).toUpperCase()}${list.substring(1)}`]();
    },
    $selectionChange(val, list) {
      this[`${list}Selections`] = val;
    },
    $getDataList({ url, params, page, before, after, list }) {
      this[`${list}Loading`] = true;
      this[`${list}Index`] = page || this[`${list}Index`];
      before && before();
      this.$http({
        url: url,
        method: 'get',
        params: {
          page: this[`${list}Index`],
          limit: this[`${list}Size`],
          ...params,
        },
      }).then(({ data }) => {
        if (data?.code === 0) {
          this[`${list}Count`] = data.page.totalCount;
          this[`${list}`] = data.page?.list ?? [];
          after && after(data);
          this[`${list}Loading`] = false;
        }
      });
    },
    $reset({ resetObj = this.dataForm, after }) {
      Object.keys(resetObj).forEach((key) => {
        resetObj[key] = null;
      });
      after && after();
    },
    _handleDelete({ url, params, data, before, after }) {
      this.loading = true;
      before && before();
      this.$http({
        url: url,
        method: 'post',
        params: params,
        data: data,
      })
        .then(({ data }) => {
          if (data && data.code === 0) {
            this.getDataList();
            this.$success();
            after && after();
          } else {
            this.$error(data.msg);
          }
        })
        .finally(() => (this.loading = false));
    },
    $handleDelete({ tip, url, params = {}, data = {}, before, after }) {
      if (tip) {
        this.$warning(tip)
          .then(() => {
            this._handleDelete({ url, params, data, before, after });
          })
          .catch(() => {});
      } else {
        this._handleDelete({ url, params, data, before, after });
      }
    },
    detailListClassName({ row }) {
      if (row.checkTime) {
        return '';
      } else {
        return 'danger-row';
      }
    },
  },
};

export const deleteListMixin = {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    _handleDelete({ url, params, data, before, after }) {
      this.loading = true;
      before && before();
      this.$http({
        url: url,
        method: 'post',
        params: params,
        data: data,
      })
        .then(({ data }) => {
          if (data && data.code === 0) {
            this.$success();
            after && after();
          } else {
            this.$error(data.msg);
          }
        })
        .finally(() => (this.loading = false));
    },
    $handlePureDelete({ tip, url, params = {}, data = {}, before, after }) {
      if (tip) {
        this.$warning(tip)
          .then(() => {
            this._handleDelete({ url, params, data, before, after });
          })
          .catch(() => {});
      } else {
        this._handleDelete({ url, params, data, before, after });
      }
    },
  },
};

export const dialogMixin = {
  data() {
    return {
      visible: false,
      loading: false,
      dataForm: {},
    };
  },
  methods: {
    _submit({ url, data, params, method, before, after }) {
      this.loading = true;
      before && before();
      this.$http({
        url: url,
        method,
        data: data,
        params: params,
      })
        .then(({ data }) => {
          if (data && data.code === 0) {
            after && after(data);
            this.$success();
            this.$emit('success');
            this.visible = false;
          } else {
            this.$error(data.msg);
          }
        })
        .finally(() => (this.loading = false));
    },
    $submit({
      url,
      data = this.dataForm,
      params,
      method = 'post',
      validate = true,
      before,
      after,
    }) {
      if (validate) {
        this.$refs.dataForm.validate((valid) => {
          if (valid) {
            this._submit({ url, data, params, method, before, after });
          }
        });
      } else {
        this._submit({ url, data, params, method, before, after });
      }
    },
    _reset({ resetForm = this.dataForm, after }) {
      Object.keys(resetForm).forEach((key) => {
        if (getType(resetForm[key]) === 'Array') resetForm[key] = [];
        else resetForm[key] = null;
      });
      after && after(resetForm);
    },
    $init({
      url,
      params,
      method = 'get',
      reset = true,
      before,
      after,
      defaultMethod,
      resetMethod = () => {},
    }) {
      this.visible = true;
      if (reset) {
        this._reset({ after: resetMethod });
        this.$nextTick(() => {
          this.$refs.dataForm.clearValidate();
        });
      }
      before && before();
      if (url) {
        this.loading = true;
        this.$http({
          url: url,
          method,
          params: params,
        }).then(({ data }) => {
          if (data && data.code === 0) {
            after && after(data);
            this.loading = false;
          }
        });
      } else {
        defaultMethod && defaultMethod();
      }
    },
  },
};

export const doubleTransMixin = {
  data() {
    return {
      pageIndex: 1,
      pageSize: 2000,
      totalPage: 0,
      dataList: [],
      dataListLoading: false,
      dataListSelections: [],
      detailListSelections: [],
      visible: false,
      loading: false,
      detailList: [],
    };
  },
  methods: {
    $getDataList({ url, params, before, after }) {
      this.dataListLoading = true;
      before && before();
      this.$http({
        url: url,
        method: 'get',
        params: {
          limit: this.pageSize,
          page: 1,
          ...this.dataForm,
          ...params,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          after && after(data);
          this.dataListLoading = false;
        }
      });
    },
    handleRowClick(row) {
      this.$refs.dataList.toggleRowSelection(row);
    },
    rowClick(row) {
      this.$refs.detailList.toggleRowSelection(row);
    },
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    selectionChange(val) {
      this.detailListSelections = val;
    },
  },
};

export const addressMixin = {
  data() {
    return {
      provinceList: [], //省份数据
      outCityList: [],
      outDisList: [],
      outTownList: [],
      outVillageList: [],
      cityList: [],
      disList: [],
      townList: [],
      villageList: [],

      dataForm: {
        shengId: '',
        shiId: '',
        quId: '',
        zhenId: '',
        cunId: '',
        detailAddress: '',
        cityId: '',

        shengIdOut: '',
        shiIdOut: '',
        quIdOut: '',
        zhenIdOut: '',
        cunIdOut: '',
        targetCityId: '',
        targetDetailAddress: '',
      },
      copyForm: {},
      inited: 0,
    };
  },
  methods: {
    //处理级联地址
    async handleAddress() {
      //省市区下拉回显
      const {
        shengIdOut,
        shiIdOut,
        quIdOut,
        zhenIdOut,
        shengId,
        shiId,
        quId,
        zhenId,
      } = this.dataForm;
      let targetAddress = `${
        this.provinceList.find((item) => item.id === shengIdOut)?.name
      }`;
      if (shiIdOut) {
        //获取目的地市列表
        const { data } = await this.getNationList(shengIdOut);
        this.outCityList = data && data.code === 0 ? data.page : [];
        targetAddress += `${
          this.outCityList.find((item) => item.id === shiIdOut)?.name
        }`;
        //获取出车区列表
        const response = await this.getNationList(shiIdOut);
        this.outDisList =
          response.data && response.data.code === 0 ? response.data.page : [];
        this.dataForm.targetCityId = shiIdOut;
        if (quIdOut) {
          //获取出车镇
          const { data } = await this.getNationList(quIdOut);
          targetAddress += `${
            this.outDisList.find((item) => item.id === quIdOut)?.name
          }`;
          this.outTownList = data && data.code === 0 ? data.page : [];
          this.dataForm.targetCityId = quIdOut;
          if (zhenIdOut) {
            //获取出车村列表
            const { data } = await this.getNationList(zhenIdOut);
            targetAddress += `${
              this.outTownList.find((item) => item.id === zhenIdOut)?.name
            }`;
            this.outVillageList = data && data.code === 0 ? data.page : [];
            this.dataForm.targetCityId = zhenIdOut;
          }
        }
      }
      let address = `${
        this.provinceList.find((item) => item.id === shengId)?.name
      }`;
      //出车地址下拉框回显
      if (shiId) {
        //获取出车市列表
        const { data } = await this.getNationList(shengId);
        this.cityList = data && data.code === 0 ? data.page : [];
        address += `${this.cityList.find((item) => item.id === shiId)?.name}`;
        //获取出车区列表
        const response = await this.getNationList(shiId);
        this.disList =
          response.data && response.data.code === 0 ? response.data.page : [];
        this.dataForm.cityId = shiId;
        if (quId) {
          //获取出车镇
          const { data } = await this.getNationList(quId);
          this.townList = data && data.code === 0 ? data.page : [];
          address += `${this.disList.find((item) => item.id === quId)?.name}`;
          this.dataForm.cityId = quId;
          if (zhenId) {
            //获取出车村列表
            const { data } = await this.getNationList(zhenId);
            this.villageList = data && data.code === 0 ? data.page : [];
            address += `${
              this.townList.find((item) => item.id === zhenId)?.name
            }`;
            this.dataForm.cityId = zhenId;
          }
        }
      }
      this.copyForm.targetAddress = `${targetAddress}${this.dataForm.targetDetailAddress}`;
      this.copyForm.address = `${address}${this.dataForm.detailAddress}`;
      this.inited = 1;
      return null;
    },
    handleOutCunChange() {
      if (this.dataForm.shiIdOut !== 306)
        this.dataForm.targetCityId = this.dataForm.cunIdOut;
    },
    //第四级地址
    async handleOutTownChange() {
      const { zhenIdOut } = this.dataForm;
      const { data } = await this.getNationList(zhenIdOut);
      if (data && data.code === 0) {
        this.outVillageList = data.page;
        let { cunIdOut, targetCityId, targetDetailAddress } = this.dataForm;
        cunIdOut = null;
        targetCityId =
          this.dataForm.shiIdOut !== 306 ? zhenIdOut : targetCityId;
        targetDetailAddress = null;
        this.dataForm = {
          ...this.dataForm,
          cunIdOut,
          targetCityId,
          targetDetailAddress,
        };
      }
    },
    //点击目的地区
    async handleOutDisChange() {
      const { quIdOut } = this.dataForm;
      const { data } = await this.getNationList(quIdOut);
      if (data && data.code === 0) {
        this.outTownList = data.page;
        this.outVillageList = [];
        let { zhenIdOut, cunIdOut, targetCityId, targetDetailAddress } =
          this.dataForm;
        zhenIdOut = null;
        cunIdOut = null;
        targetCityId = quIdOut;
        targetDetailAddress = null;
        this.dataForm = {
          ...this.dataForm,
          zhenIdOut,
          cunIdOut,
          targetCityId,
          targetDetailAddress,
        };
      }
    },
    //点击目的地城市
    async handleOutCityChange() {
      const { shiIdOut } = this.dataForm;
      const { data } = await this.getNationList(shiIdOut);
      if (data && data.code === 0) {
        this.outDisList = data.page;
        this.outTownList = [];
        this.outVillageList = [];
        let {
          quIdOut,
          zhenIdOut,
          cunIdOut,
          targetCityId,
          targetDetailAddress,
        } = this.dataForm;
        quIdOut = null;
        zhenIdOut = null;
        cunIdOut = null;
        targetCityId = shiIdOut;
        targetDetailAddress = null;
        this.dataForm = {
          ...this.dataForm,
          quIdOut,
          zhenIdOut,
          cunIdOut,
          targetCityId,
          targetDetailAddress,
        };
      }
    },
    //点击目的地省份
    async handleOutProvinceChange() {
      const { shengIdOut } = this.dataForm;
      const { data } = await this.getNationList(shengIdOut);
      if (data && data.code === 0) {
        this.outCityList = data.page;
        this.outDisList = [];
        this.outTownList = [];
        this.outVillageList = [];
        let {
          shiIdOut,
          quIdOut,
          zhenIdOut,
          cunIdOut,
          targetCityId,
          targetDetailAddress,
        } = this.dataForm;
        shiIdOut = null;
        quIdOut = null;
        zhenIdOut = null;
        cunIdOut = null;
        targetCityId = shengIdOut;
        targetDetailAddress = null;
        this.dataForm = {
          ...this.dataForm,
          shiIdOut,
          quIdOut,
          zhenIdOut,
          cunIdOut,
          targetCityId,
          targetDetailAddress,
        };
      }
    },
    //点击出车镇（第四级）
    async handleTownChange() {
      const { zhenId } = this.dataForm;
      const { data } = await this.getNationList(zhenId);
      if (data && data.code === 0) {
        this.villageList = data.page;
        let { cunId, cityId, detailAddress } = this.dataForm;
        cunId = '';
        //cityId = !data.page.length ? zhenId : "";
        cityId = zhenId;
        detailAddress = '';
        this.dataForm = { ...this.dataForm, cunId, cityId, detailAddress };
      }
    },
    //点击出车区（第三级）
    async handleDisChange() {
      const { quId } = this.dataForm;
      const { data } = await this.getNationList(quId);
      if (data && data.code === 0) {
        this.townList = data.page;
        this.villageList = [];
        let { zhenId, cunId, cityId, detailAddress } = this.dataForm;
        zhenId = '';
        cunId = '';
        // cityId = !data.page.length ? quId : "";
        cityId = quId;
        detailAddress = '';
        this.dataForm = {
          ...this.dataForm,
          zhenId,
          cunId,
          cityId,
          detailAddress,
        };
      }
    },
    //点击出车城市
    async handleCityChange() {
      const { shiId } = this.dataForm;
      const { data } = await this.getNationList(shiId);
      if (data && data.code === 0) {
        this.disList = data.page;
        this.townList = [];
        this.villageList = [];
        let { quId, zhenId, cunId, cityId, detailAddress } = this.dataForm;
        quId = '';
        zhenId = '';
        cunId = '';
        //cityId = !data.page.length ? shiId : "";
        cityId = shiId;
        detailAddress = '';
        this.dataForm = {
          ...this.dataForm,
          quId,
          zhenId,
          cunId,
          cityId,
          detailAddress,
        };
      }
    },
    //点击出车省份
    async handleProvinceChange() {
      const { shengId } = this.dataForm;
      const { data } = await this.getNationList(shengId);
      if (data && data.code === 0) {
        this.cityList = data.page;
        this.disList = [];
        this.townList = [];
        this.villageList = [];
        let { shiId, quId, zhenId, cunId, cityId, detailAddress } =
          this.dataForm;
        shiId = '';
        quId = '';
        zhenId = '';
        cunId = '';
        cityId = shengId;
        detailAddress = '';
        this.dataForm = {
          ...this.dataForm,
          shiId,
          quId,
          zhenId,
          cunId,
          cityId,
          detailAddress,
        };
      }
    },
    //获取省份列表
    async getProvinceList() {
      const { data } = await this.getNationList(0);
      if (data?.code === 0) this.provinceList = data.page;
    },
    //获取省市区列表
    getNationList(parentId) {
      return this.$http({
        url: `/cms/byCity/listCombo/${parentId}`,
        method: 'get',
      });
    },
  },
};

export const tableMixin = {
  methods: {
    $table({ name, data = [] }) {
      let error;
      data
        .filter((item) => item.required)
        .forEach((item) => {
          if (!item?.value) error = `${item.field}不能为空`;
        });
      if (error) return this.$error(error);
      const params = data.map((item) => `${item.name}=${item.value}`).join('&');
      window.open(`${process.env.VUE_APP_TABLE_BASE_URL}${name}&${params}`);
    },
  },
};

export const fileMixin = {
  data() {
    return {
      file: '',
      fileList: [],
    };
  },
  methods: {
    _request({ url, method = 'post', data, headers = {} }) {
      return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.open(method, url);
        Object.keys(headers).forEach((key) =>
          xhr.setRequestHeader(key, headers[key]),
        );
        xhr.send(data);
        xhr.onload = (e) => {
          resolve({
            data: e.target.response,
          });
        };
      });
    },
    _createFileChunk(file, size) {
      const fileChunkList = [];
      let cur = 0;
      while (cur < file.size) {
        fileChunkList.push({ file: file.slice(cur, cur + size) });
        cur += size;
      }
      return fileChunkList;
    },
    async _merge(size) {
      await this._request({
        url: `${process.env.VUE_APP_BIG_FILE_UPLOAD}/merge`,
        headers: { 'content-type': 'application/json' },
        data: JSON.stringify({ filename: this.file.name, size }),
      });
    },
    async _uploadChunks(size) {
      const requestList = this.fileList
        .map(({ chunk, hash }) => {
          const formData = new FormData();
          formData.append('chunk', chunk);
          formData.append('hash', hash);
          formData.append('filename', this.file.name);
          return formData;
        })
        .map(async (data) =>
          this._request({ url: process.env.VUE_APP_BIG_FILE_UPLOAD, data }),
        );
      await Promise.all(requestList);
      await this._merge(size);
    },
    $bigFileUpload({ file, size = 5 * 1024 * 1024 }) {
      this.file = file;
      this.fileList = this._createFileChunk(file, size).map((item, index) => ({
        chunk: item.file,
        hash: `${file.name}-${index}`,
      }));
      this._uploadChunks(size);
    },
    $beforeFileUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$error('发送的图片大小不能超过 10MB!');
      }
      return isLt10M;
    },
    $beforeRemove(file) {
      return this.$warning(`是否确认删除文件${file.name}?`);
    },
    $beforeImgUpload(file) {
      const isImg = ['image/jpeg', 'image/jpg', 'image/png'].includes(
        file.type,
      );
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isImg) {
        this.$error('发送的图片只能是 JPG,JPEG,PNG 格式!');
      }
      if (!isLt10M) {
        this.$error('发送的图片大小不能超过 10MB!');
      }
      return isImg && isLt10M;
    },
    // $preview(file) {
    //   const { filePath, name, url, fileName } = file;
    //   if (filePath && name) {
    //     window.open(`${baseFile}${file.filePath}?fileName=${file.name}`);
    //     return;
    //   }
    //   if (filePath && fileName) {
    //     window.open(`${baseFile}${file.filePath}?fileName=${file.fileName}`);
    //     return;
    //   }
    //   if (url && fileName) {
    //     window.open(`${baseFile}${file.url}?fileName=${file.fileName}`);
    //     return;
    //   }
    //   if (url && name) {
    //     window.open(`${baseFile}${file.url}?fileName=${file.name}`);
    //     return;
    //   }
    // },
    $upload({ file, fileType, after, before }) {
      const data = new FormData();
      data.append('files', file);
      before && before();
      this.$http({
        url: '/upload/uploadFile',
        method: 'post',
        params: { fileType },
        data,
      }).then(({ data }) => {
        if (data && data.code === 0) {
          after && after(data);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
};

export const IdCardMixin = {
  methods: {
    $readIdCard({
      url = 'http://127.0.0.1:5190/identify-card/read',
      before,
      after,
    }) {
      before && before();
      this.$http({
        url,
        method: 'GET',
        crossDomain: true,
        withCredentials: false,
      }).then(({ data }) => {
        if (data.success) {
          this.$message({
            type: 'success',
            message: '读取成功',
            duration: 1000,
          });
          after && after(data);
        } else {
          this.$error('读取失败，请重试');
          this.$confirm(
            '无法读取硬件信息, 是否需要进行下载读卡器插件?',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            },
          )
            .then(() => {
              this.$message({
                type: 'success',
                message: '确认成功，开始下载成功!',
              });
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '取消下载',
              });
            });
        }
      });
    },
  },
};

export const fingerMixin = {
  methods: {
    $finger({ deathId, type, before, after }) {
      before && before();
      this.$http({
        url: `/cms/byFamilyFinger/info/${deathId}`,
        method: 'get',
        responseType: 'arraybuffer',
      }).then(({ data }) => {
        const templateData = data;
        const ws = new WebSocket('ws://127.0.0.1:5190/new-fingerprint');

        function getMessage(data) {
          return new Promise((resolve) => {
            if (data) {
              ws.send(JSON.stringify(data));
            }
            ws.addEventListener(
              'message',
              (e) => {
                let _data;
                if (typeof e.data === 'string') {
                  _data = JSON.parse(e.data);
                } else if (e.data instanceof Blob) {
                  _data = e.data;
                }
                resolve(_data);
              },
              { once: true },
            );
          });
        }

        ws.addEventListener('open', async () => {
          let data;
          data = await getMessage({ action: 'Open' });
          if (!data.success) {
            return this.$error(data.msg);
          }
          data = await getMessage({
            action: 'SetImageSize',
            width: 256,
            height: 288,
          });
          if (!data.success) {
            return this.$error(data.msg);
          }

          ws.send(JSON.stringify({ action: 'DownloadTemplate', buffer: 'B' }));
          ws.send(templateData);

          data = await getMessage();
          if (!data.success) {
            return this.$error(data.msg);
          }

          this.$message('请按压手指');
          let count = 0;
          while (count < 5) {
            data = await getMessage({ action: 'GetImage' });
            if (data.success) {
              count++;
            }
          }
          data = await getMessage({ action: 'GenerateTemplate', buffer: 'A' });
          if (!data.success) {
            return this.$error(data.msg);
          }
          data = await getMessage({ action: 'Match' });
          if (!data.success) {
            return this.$error(data.msg);
          } else {
            after && after(type);
          }
          ws.send(JSON.stringify({ action: 'Close' }));
        });
      });
    },
  },
};

export const validateMixin = {
  methods: {
    $validateTel(rule, value, callback) {
      if (!value?.toString()?.trim().length)
        return callback(new Error('固定电话不能为空'));
      if (!isTel(value)) {
        callback(new Error('固定电话不合法'));
      } else {
        callback();
      }
    },
    $validatePhone(rule, value, callback) {
      if (!value.toString().trim().length)
        return callback(new Error('固定电话不能为空'));
      if (!isPhone(value)) {
        callback(new Error('固定电话不合法'));
      } else {
        callback();
      }
    },
    $validateMobile(rule, value, callback) {
      if (!value) callback(new Error('请输入手机号码'));
      else if (!isMobile(value)) callback(new Error('手机号码不正确'));
      else callback();
    },
    $validateNum(rule, value, callback) {
      if (!onlyNum(value)) {
        callback('只能输入数字');
      } else if (!value || !`${value}`?.trim()) {
        callback('不能为空');
      } else if (value === '-') {
        callback();
      } else {
        callback();
      }
    },
    $validateIdCard(rule, value, callback) {
      if (!value || !value.trim()) {
        callback(new Error('身份证不能为空'));
      } else if (!validateIdCardDate(value)) {
        callback(new Error('身份证不正确'));
      } else {
        callback();
      }
    },
  },
};

export const robMixin = {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    $rob({
      url = '/cms/tBusinessCost/register',
      deathId,
      type,
      businessNum,
      after,
      rob,
    }) {
      this.loading = true;
      this.$http({
        url: url,
        method: 'get',
        params: {
          deathId,
          type,
          businessNum,
        },
      })
        .then(({ data }) => {
          if (data && !data.message.msg) {
            after && after(data);
          } else {
            this.$confirm(data.message.msg, '提示', {
              confirmButtonText: '抢单',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.$http({
                  url: '/cms/tBusinessCost/robBusiness',
                  method: 'post',
                  params: {
                    deathId,
                    type,
                    businessNum,
                  },
                });
                rob && rob();
              })
              .catch(() => {});
          }
        })
        .finally(() => (this.loading = false));
    },
    $deleteRob({ url = '/cms/tBusinessCost/deleteRegister', deathId, type }) {
      this.$http({
        url: url,
        method: 'post',
        params: {
          deathId,
          type,
        },
      });
    },
  },
};

// export const printMixin = {
//   data() {
//     return {
//       loading: false,
//     };
//   },
//   methods: {
//     $print({ wrap = 'printContent', documentTitle }) {
//       html2canvas(this.$refs[wrap], {
//         logging: true, //日志开关，便于查看html2canvas的内部执行流程
//         width: this.$refs[wrap].clientWidth, //dom 原始宽度
//         height: this.$refs[wrap].clientHeight,
//         allowTaint: true, //允许跨域图片
//         taintTest: false, //是否在渲染前测试图片
//         loading: false,
//         scrollY: 0,
//         scrollX: 0,
//         useCORS: true, // 【重要】开启跨域配置
//       })
//         .then((canvas) => {
//           this.$nextTick(() => {
//             const url = canvas.toDataURL();
//             printJS({
//               printable: url,
//               type: 'image',
//               documentTitle,
//             });
//             this.loading = false;
//           });
//         })
//         .catch(() => {});
//     },
//     _testIsInstall() {
//       if (!window.getCLodop) {
//         this.loading = true;
//         this.$http({
//           url: this.$http.adornUrl('/cms/byCarOrder/getClodop'),
//           method: 'get',
//         })
//           .then(({ data }) => {
//             if (data && data.code === 0) {
//               window.open(
//                 `${process.env.VUE_APP_UPLOAD_URL}/${data.clodopUrl}`,
//               );
//             } else {
//               this.$message.error(data.msg);
//             }
//           })
//           .finally(() => (this.loading = false));
//         this.$message({
//           type: 'warning',
//           duration: 0,
//           message: '检测到本地没有安装打印软件，请先下载安装再进行打印操作。',
//           showClose: true,
//         });
//         return false;
//       } else {
//         return true;
//       }
//     },
//     $printCode(businessNum, name, sex, address, freezerNum) {
//       if (this._testIsInstall()) {
//         this.loading = true;
//         setTimeout(() => {
//           this.loading = false;
//         }, 5000);
//         printBarCode(name, sex, businessNum, address, freezerNum);
//       }
//     },
//   },
// };

// export const exportMixin = {
//   methods: {
//     $export(name) {
//       const wb = XLSX.utils.table_to_book(this.$refs.dataList.$el, {
//         raw: true,
//       });
//       const wbout = XLSX.write(wb, {
//         bookType: 'xlsx',
//         bookSST: true,
//         type: 'array',
//       });
//       try {
//         FileSaver.saveAs(
//           new Blob([wbout], { type: 'application/octet-stream' }),
//           `${name}.xlsx`,
//         );
//       } catch (e) {
//         if (typeof console !== 'undefined') {
//           console.log(e, wbout);
//         }
//       }
//       return wbout;
//     },
//   },
// };
